import { orderBy } from "lodash";
// [0, 1, 2, 3, 4] -> [4, 2, 0, 1, 3]
export var orderFromCenter = function(arr, orderByKey) {
    if (orderByKey) arr = orderBy(arr, orderByKey);
    // Even indexes in descending order followed by odd indexes in ascending order
    var indexes = orderBy(arr.map(function(_, i) {
        return i;
    }), function(i) {
        return (i % 2 == 0 ? -1 : +1) * i;
    });
    return indexes.map(function(i) {
        return arr[i];
    });
};
var dateStrings = new Map();
export var getMonthYearDateString = function(date) {
    // Memoize date rendering, as it's very expensive
    var yearMonth = date.slice(0, 7) // YYYY-MM
    ;
    if (dateStrings.has(yearMonth)) return dateStrings.get(yearMonth);
    var dateString = new Date(date).toLocaleDateString("en-UK", {
        year: "numeric",
        month: "long"
    });
    dateStrings.set(yearMonth, dateString);
    return dateString;
};
