import _object_spread from "@swc/helpers/src/_object_spread.mjs";
import { createTheme, responsiveFontSizes } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import { grey } from "@material-ui/core/colors";
import { withStyles } from "@material-ui/core/styles";
import merge from "lodash/merge";
import { getHeadingStyles, globalStyles } from "@aletheia/common/styles";
var primaryColor = "#BA175B";
var secondaryColor = "#FAF4F4";
var textColor = "#222222";
var palette = {
    primary: {
        main: primaryColor,
        light: secondaryColor,
        dark: textColor,
        contrastText: "#FFFFFF"
    },
    secondary: {
        main: "#424242",
        light: "#757575",
        dark: "#212121",
        contrastText: textColor
    },
    sand: {
        main: "#FBE9CA",
        light: "#fbedd4",
        dark: "#afa38d",
        contrastText: "#343434"
    },
    yellow: {
        main: "#E86F2B",
        light: "#FAE2D5",
        dark: "#E86F2B",
        contrastText: "#FFFFFF"
    },
    orange: {
        main: "#CC4115",
        light: "#F5D9D0",
        dark: "#CC4115",
        contrastText: "#FFFFFF"
    },
    red: {
        main: "#BA2934",
        light: "#F1D4D6",
        dark: "#BA2934",
        contrastText: "#FFFFFF"
    },
    pink: {
        main: "#DA3552",
        light: "#F8D7DC",
        dark: "#DA3552",
        contrastText: "#FFFFFF"
    },
    purple: {
        main: "#BA175B",
        light: "#F1D1DE",
        dark: "#BA175B",
        contrastText: "#FFFFFF"
    }
};
/** Font stacks */ var sansSerifStack = '"Metropolis", Helvetica, "Helvetica Neue", Arial, sans-serif';
var serifStack = 'Merriweather, Georgia, Times, "Times New Roman", serif';
var monospaceStack = 'Consolas, "Andale Mono WT", "Andale Mono", "Lucida Console", "Lucida Sans Typewriter", "DejaVu Sans Mono", "Bitstream Vera Sans Mono", "Liberation Mono", "Nimbus Mono L", Monaco, "Courier New", Courier, monospace;';
/** Base styles for all heading tags */ var headingStyle = {
    fontFamily: sansSerifStack,
    fontWeight: 700
};
// Apply heading styles to all H<1...6> elements
var headings = getHeadingStyles(headingStyle);
/** Global styles to apply everywhere */ var themeGlobals = {
    html: {
        // Height of fixed top menu, allows to scroll to element with padding
        scrollPaddingTop: "64px",
        "@media (max-width: 600px)": {
            scrollPaddingTop: "48px"
        }
    },
    // Make icons that are inlined with text look reasonable
    ".MuiTypography-root, .MuiTableCell-root": {
        "& .MuiSvgIcon-root": {
            position: "relative",
            top: "0.15em",
            fontSize: "1em"
        }
    },
    ".page-content .MuiTypography-body1": {
        lineHeight: "1.6rem",
        "&.MuiTypography-gutterBottom": {
            marginBottom: "1em"
        }
    }
};
/**
 * Global CSS declarations
 *
 * Built by recursively merging theme globals into global styles
 */ var globals = merge({}, globalStyles, themeGlobals);
/** Override MUI components */ var overrides = {
    // Use the CSS baseline to create global styles
    MuiCssBaseline: {
        "@global": _object_spread({}, globals)
    },
    MuiTypography: {
        root: {
            "& code": {
                fontFamily: monospaceStack,
                whiteSpace: "nowrap",
                padding: "4px 5px 6px",
                background: grey[100],
                color: grey[700],
                fontWeight: 700,
                borderRadius: 5
            },
            "& .MuiLink-button": {
                marginTop: -1
            }
        }
    },
    MuiButton: {
        root: {
            fontFamily: sansSerifStack,
            textTransform: "none",
            fontWeight: 700
        }
    },
    MuiToolbar: {
        root: {
            "& .MuiButton-root": {
                paddingLeft: "16px",
                paddingRight: "16px"
            },
            "& > div > div": {
                justifyContent: "end"
            }
        }
    },
    MuiMenu: {
        paper: {
            borderRadius: 0,
            "& .MuiButton-label": {
                paddingTop: "2px",
                paddingBottom: "2px"
            }
        }
    },
    MuiLink: {
        button: {
            marginTop: -2,
            font: "inherit",
            lineHeight: "inherit",
            textAlign: "inherit"
        }
    },
    MuiDrawer: {
        root: {
            "& .MuiButton-label": {
                textTransform: "none"
            },
            "& .MuiListItemText-root .MuiTypography-root": {
                fontFamily: sansSerifStack
            }
        }
    },
    MuiToggleButtonGroup: {
        root: {
            width: "100%"
        }
    },
    MuiToggleButton: {
        root: {
            borderColor: palette.primary.main,
            fontFamily: sansSerifStack,
            color: palette.primary.main,
            textTransform: "initial",
            "&.Mui-selected": {
                borderLeftColor: "".concat(palette.primary.main, " !important"),
                color: palette.primary.dark,
                backgroundColor: palette.primary.light,
                boxShadow: "inset 0 0 3px ".concat(palette.primary.main),
                "&:hover": {
                    backgroundColor: "".concat(palette.primary.light, " !important")
                }
            }
        }
    },
    MuiStepIcon: {
        completed: {
            color: "".concat(palette.primary.dark, " !important")
        }
    },
    MuiFormControlLabel: {
        label: {
            fontSize: "0.875rem",
            lineHeight: 1.43
        }
    },
    MuiChip: {
        label: {
            fontFamily: sansSerifStack
        }
    },
    MuiTooltip: {
        tooltip: {
            fontFamily: sansSerifStack
        }
    },
    MuiTab: {
        root: {
            fontFamily: sansSerifStack,
            textTransform: "initial"
        }
    }
};
var theme = responsiveFontSizes(createTheme({
    props: {
        MuiButtonBase: {
            disableRipple: true
        }
    },
    typography: _object_spread({
        fontFamily: serifStack,
        body1: {
            lineHeight: 2.2
        }
    }, headings),
    palette: palette,
    overrides: overrides
}));
export var SansSerifTypography = withStyles({
    root: {
        fontFamily: sansSerifStack
    }
})(Typography);
export default theme;
