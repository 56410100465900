import { usePreviewSubscription } from "../../lib/sanity/sanity-nextjs-utils";
import { filterDocumentsToSingleItem } from "../utils";
/**
 * Hook to retrieve document previews
 * Requires Nextjs preview cookie to be set
 * Returns a PartialDocument as it might be a draft that doesn't contain all the fields
 */ export var useDocumentPreviewSubscription = function(groqQuery, param) {
    var initialData = param.initialData, enabled = param.enabled;
    var ref = usePreviewSubscription(groqQuery, {
        // This is why it's important to fetch *draft* content server-side!
        initialData: initialData,
        // The passed-down preview context determines whether this function does anything
        enabled: enabled
    }), previewPost = ref.data;
    // Query could return multiple drafts, so we may need to filter it down
    return filterDocumentsToSingleItem(previewPost, enabled);
};
